/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({
  description,
  lan,
  meta,
  title,
  image,
  url,
  typ,
  titleTemplate,
}) {
  const { site, img } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        img: sanityStatic {
          siteImage {
            asset {
              url
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const mainTitle = titleTemplate || site.siteMetadata?.title
  const im = image ? image : img.siteImage.asset.url
  const type = typ ? typ : "website"

  return (
    <Helmet
      htmlAttributes={{
        lang: lan === "en" ? "en-US" : "de-DE",
      }}
      title={title}
      titleTemplate={mainTitle}
    >
      <meta name="title" content={title} />
      <link rel="canonical" href={url} />
      <meta name="description" content={metaDescription} />
      <meta name="image" content={im} />
      <meta property="og:locale" content={lan === "en" ? "en_US" : "de_DE"} />
      <meta property="og:type" content={type} />
      {title && <meta property="og:title" content={title} />}
      {metaDescription && (
        <meta property="og:description" content={metaDescription} />
      )}
      {url && <meta property="og:url" content={url} />}
      <meta property="og:site_name" content="haackschubert.de" />
      {im && <meta property="og:image" content={im} />}
      <meta name="twitter:site" content="haackschubert.de" />
      <meta name="twitter:card" content="summary_large_image" />
      {title && <meta name="twitter:title" content={title} />}
      {metaDescription && (
        <meta name="twitter:description" content={metaDescription} />
      )}
      {im && <meta name="twitter:image" content={im} />}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en-US`,
  description: `This is the platform where you can find the best solution related to Law`,
  title: `haackschubert`,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo
